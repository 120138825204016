<template>
 
<div class="banner" :style="{background:IndustrialbannerData.background}" >
            <div class="w"> 
                <div class="Img"><img class="imgbg" :src="IndustrialbannerData.img" mode="" :alt=IndustrialbannerData.H1 /></div>
                <div class="info12">
                    <div class="title" :style="{color:IndustrialbannerData.color?IndustrialbannerData.color:'#ffffff'}">{{IndustrialbannerData.H1}}</div>
   
                    <div class="text" :style="{color:IndustrialbannerData.color?IndustrialbannerData.color:'#ffffff'}">{{IndustrialbannerData.info}}</div>
                  
                      <!-- <div class="btn"   @click="isTou()"  >Get Instant Quote</div> -->
                </div> 
            </div>
        </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    isTou() {
      this.$store.dispatch("setDialogVisible", true);
    }
  },
  mounted() {},
  created() {},
  props: { IndustrialbannerData: {} },
  watch: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.banner {
  // background: url('~@/assets/image/Industrialbar.png') no-repeat;
 
  background: white;
  background-position: center;
  width: 100%;
  height:620px;

  display: flex;
  transition: 0.25s;
  align-items: center;
  .w {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .btn {
    display: inline-block;

    cursor: pointer;
    border: 1px solid black;

    text-align: center;
    color: black;
    transition: 0.25s;
  }
  .info12 {
    font-size: 24px;
    align-self: center;

    color: #000;

    z-index: 11;
    //   width: 53%;
    transition: 0.25s;

    > .text {
      // font-size: 16px;
      color:white;
      transition: 0.25s;
      // line-height: 36px;
    }
    > .title {
      // font-size: 48px;
      margin-bottom: 20px;
      color: white;
      transition: 0.25s;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 550px) {
  //最小  min-width="330px"

.info12{
    margin-top: 13vw;
}
  .w {
    width: 90%;
    min-width: 330px;
  }
  .banner {
       height:500px;
  }
  .btn {
    padding: 10px 10px;
    font-size: 15px;
    margin: 20px 0;
  }
  .title {
    font-size: 24px;
  }
  .info12 {
    width: 69%;
    min-width: 225px;
  }

  img {
  max-width: 42vw;
    min-width: 330px;
    position: absolute;
        margin-left: -43%;
    margin-top: 14px;
  }
  .text {
    line-height: 27px;
    font-size: 17px;
  }
}

@media screen and (min-width: 550px) and (max-width: 800px) {
  .w {
    width: 90%;
    min-width: 330px;
  }
  .banner {
       height:500px;
  }
  .btn {
    padding: 10px 15px;
    font-size: 16px;
    margin: 20px 0;
  }
  .title {
          margin-top: 1vw;
    font-size: 28px;
  }

  .info12 {
    width: 60%;
    margin-left: 44%;
  }

  img {
    max-width: 42vw;
    min-width: 330px;
    position: absolute;
left: -11vw;
    margin-top: -8vw;
  }
  .Img {
    // width: 40%;
  }
  .text {
    line-height: 27px;
    font-size: 15px;
  }
}

//平板
@media screen and (min-width: 800px) and (max-width: 1020px) {
  .w {
    width: 90%;
  }
  .banner {
      height: 62vw;
  }
  .btn {
    padding: 15px 20px;
    font-size: 16px;
    margin: 20px 0;
  }
  .title {
    font-size: 30px;
  }
  .info12 {
    width: 50%;
        margin-top: 1vw;
  }
  img {
    width: 100%;
  }
  .Img {
    width: 50%;
  }
  .text {
    line-height: 36px;
    font-size: 16px;
  }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  .w {
    width: 90%;
  }
  .banner {
    height: 620px;
  }
  .btn {
    padding: 15px 20px;
    font-size: 16px;
    margin: 20px 0;
  }
  .title {
    font-size: 35px;
  }
  .info {
    width: 53%;
    align-items: center;
        margin-top: 1vw;
  }
  img {
    width: 100%;
  }
  .Img {
    width: 100%;
  }
  .text {
    line-height: 36px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1350px) {
  //最大限制   width="1280px"

  .w {
    width: 1280px;
    align-items: center;
  }
  .banner {
    height: 620px;
  }
  .btn {
    padding: 15px 20px;
    font-size: 16px;
    margin: 20px 0;
  }
  .title {
    font-size: 55px;
  }
  .info12 {
    width: 48%;
        margin-top: 1vw;
  }
  img {
    width: 100%;
  }
  .img {
    // width: 63%;
  }
  .text {
    line-height: 36px;
    font-size: 24px;
  }
}
</style>